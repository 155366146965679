import { stateManager } from '../model-state/use-model-state';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { isSameObjectPath } from '../model/ugla-types-tools';
import { useViewerStore } from './store';
import { Asset, Modification, Modification3D, ModificationLight } from '../model/ugla-filetype';
import * as THREE from 'three';

/**
 * Creates all bindings between the stateManager and the library state.
 * This function contains quite a lot of procedural code that is required
 * to handle THREEjs objects.
 * It kind of "reproduce" a reactive aproach from THREEjs interface by connecting
 * modification observed in the stateManager to the THREEjs objects.
 */
export const initStateStoreBindings = () => {
  stateManager.on('scene', (event) => {
    event.removed.forEach((obj) => {
      if(obj.asset.type === '3D') {
        useViewerStore.getState().actions.model.removeNative(obj.id);
      }
    })

    useViewerStore.getState().actions.model.setLoadingStatus('loading', 0);
    const assets3D = event.added.filter(obj => obj.asset.type === '3D');
    const progressById : Record<string, number> = {};
    const loaderPromises = assets3D
      .map((obj, assetIndex) => {
        const loader = new GLTFLoader();

        return loader.loadAsync(
          obj.asset.url,
          (xhr) => {
            const assetProgress =xhr.loaded / xhr.total;
            progressById[obj.id] = assetProgress;
            const progress = Object.values(progressById).reduce((acc, curr) => acc + curr, 0) / assets3D.length;
            useViewerStore.getState().actions.model.setLoadingStatus('loading', progress);
          }
        )
        .then((gltf) => {
          const native = gltf.scene;
          useViewerStore.getState().actions.model.addNative(obj.id, native);
        })
        .catch((error) => {
          console.error(error);
          useViewerStore.getState().actions.model.setLoadingError(obj.id, `${error}`);
        });
      })

    Promise.allSettled(loaderPromises).then(() => {
      useViewerStore.getState().actions.model.setLoadingStatus('loaded', 1);
    })
  })

  stateManager.on('mods', (event) => {
    const state = useViewerStore.getState();
    const ids = Object.keys(state.native);
    const {getObjectFromPath} = state.actions.model;
    const {setPreviousMods} = state.actions.mods;
    const {texture} = state.actions.textures;

    const mods = event.value;

    ids.forEach(objectId => {
      const previousMods = useViewerStore.getState().previousMods[objectId] || [];

      // 3D mods
      previousMods.filter((mod) : mod is Modification3D<Asset> => mod.type === '3D' && mod.path.objectId === objectId).forEach(mod => {
        const object = getObjectFromPath({objectId : mod.path.objectId, path : [...mod.path.path]})?.obj;

        // Since THREE api is imperative and not declarative, reset previous modifications from THREE
        if(object) {
          if(mod.position && object.userData.originalPosition) {
            object.position.set(object.userData.originalPosition.x, object.userData.originalPosition.y, object.userData.originalPosition.z)
            delete object.userData.originalPosition;
          }

          if(mod.rotation && object.userData.originalRotation) {
            object.rotation.set(object.userData.originalRotation.x, object.userData.originalRotation.y, object.userData.originalRotation.z)
            delete object.userData.originalRotation;
          }

          if(mod.scale && !object.userData.originalScale) {
            object.scale.set(object.userData.originalScale.x, object.userData.originalScale.y, object.userData.originalScale.z)
            delete object.userData.originalScale;
          }

          if(mod.material && !object.userData.originalMaterial) {
            if((object as THREE.Mesh).isMesh) {
              (object as THREE.Mesh).material = object.userData.originalMaterial;
            }
            delete object.userData.originalMaterial;
          }
        }
      })
      // Light mods
      previousMods.filter((mod) : mod is ModificationLight => mod.type === 'light' && mod.path.objectId === objectId).forEach(mod => {
        const object = getObjectFromPath({objectId : mod.path.objectId, path : [...mod.path.path]})?.obj;

        // Since THREE api is imperative and not declarative, reset previous modifications from THREE
        if(object && (object as THREE.Light).isLight) {
          const light = object as THREE.Light;
          const pointLight = (light as THREE.PointLight).isPointLight ? light as THREE.PointLight : undefined;
          const spotLight = (light as THREE.SpotLight).isSpotLight ? light as THREE.SpotLight : undefined;

          if(mod.position && light.userData.originalPosition) {
            light.position.set(light.userData.originalPosition.x, light.userData.originalPosition.y, light.userData.originalPosition.z)
            delete light.userData.originalPosition;
          }
          // TODO : implement light target
          // if(mod.target && light.userData.originalTarget) {
          //   light.position.set(light.userData.originalTarget.x, light.userData.originalTarget.y, light.userData.originalTarget.z)
          //   delete light.userData.originalTarget;
          // }
          if(mod.intensity && light.userData.originalIntensity) {
            light.intensity = light.userData.originalIntensity;
            delete light.userData.originalIntensity;
          }
          if(mod.color && light.userData.originalColor) {
            light.color.set(light.userData.originalColor);
            delete light.userData.originalColor;
          }
          if(pointLight && mod.distance && pointLight.userData.originalDistance) {
            pointLight.distance = pointLight.userData.originalDistance;
            delete pointLight.userData.originalDistance;
          }
          if(pointLight && mod.decay && pointLight.userData.originalDecay) {
            pointLight.decay = pointLight.userData.originalDecay;
            delete pointLight.userData.originalDecay;
          }
          if(spotLight && mod.distance && spotLight.userData.originalDistance) {
            spotLight.distance = spotLight.userData.originalDistance;
            delete spotLight.userData.originalDistance;
          }
          if(spotLight && mod.decay && spotLight.userData.originalDecay) {
            spotLight.decay = spotLight.userData.originalDecay;
            delete spotLight.userData.originalDecay;
          }
          if(spotLight && mod.angle && spotLight.userData.originalAngle) {
            spotLight.angle = spotLight.userData.originalAngle;
            delete spotLight.userData.originalAngle;
          }
          if(spotLight && mod.penumbra && spotLight.userData.originalPenumbra) {
            spotLight.penumbra = spotLight.userData.originalPenumbra;
            delete spotLight.userData.originalPenumbra;
          }
        }
      })


      const newMods = [
        ...mods
        .filter((mod) : mod is Modification3D<Asset> => mod.type === '3D' && mod.path.objectId === objectId)
        .map(mod => ({...mod})) // remove readonly
        .reduce((mods, nextMod) => {
        const existing = mods.find(m => isSameObjectPath(m.path, nextMod.path));
        if(existing) {
          existing.position = nextMod.position || existing.position;
          existing.rotation = nextMod.rotation || existing.rotation;
          existing.scale    = nextMod.scale    || existing.scale;
          existing.material = {...existing.material, ...nextMod.material};
        }
        else {
          mods.push(nextMod);
        }
        return mods;
      }, [] as Modification3D<Asset>[]),
        ...mods
        .filter((mod) : mod is ModificationLight => mod.type === 'light' && mod.path.objectId === objectId)
        .map(mod => ({...mod})) // remove readonly
        .reduce((mods, nextMod) => {
        const existing = mods.find(m => isSameObjectPath(m.path, nextMod.path));
        if(existing) {
          existing.position  = nextMod.position  || existing.position;
          existing.intensity = nextMod.intensity || existing.intensity;
          existing.color     = nextMod.color     || existing.color;
          existing.distance  = nextMod.distance  || existing.distance;
          existing.decay     = nextMod.decay     || existing.decay;
          existing.angle     = nextMod.angle     || existing.angle;
          existing.penumbra  = nextMod.penumbra  || existing.penumbra;
        }
        else {
          mods.push(nextMod);
        }
        return mods;
      }, [] as ModificationLight[]),
      ]

      setPreviousMods(objectId, newMods);

      newMods.forEach(mod => {
        const object = getObjectFromPath({objectId : mod.path.objectId, path : [...mod.path.path]})?.obj;

        if(object && mod.type === '3D') {
          if(mod.position) {
            if(!object.userData.originalPosition) {
              object.userData.originalPosition = object.position.clone();
            }
            object.position.set(...mod.position);
          }
          else {
            if(object.userData.originalPosition) {
              object.position.set(object.userData.originalPosition.x, object.userData.originalPosition.y, object.userData.originalPosition.z)
              delete object.userData.originalPosition;
            }
          }

          if(mod.rotation) {
            if(!object.userData.originalRotation) {
              object.userData.originalRotation = object.rotation.clone();
            }
            object.rotation.set(mod.rotation[0]/180*Math.PI, mod.rotation[1]/180*Math.PI, mod.rotation[2]/180*Math.PI);
          }
          else {
            if(object.userData.originalRotation) {
              object.rotation.set(object.userData.originalRotation.x, object.userData.originalRotation.y, object.userData.originalRotation.z)
              delete object.userData.originalRotation;
            }
          }

          if(mod.scale) {
            if(!object.userData.originalScale) {
              object.userData.originalScale = object.scale.clone();
            }
            object.scale.set(...mod.scale);
          }
          else {
            if(object.userData.originalScale) {
              object.scale.set(object.userData.originalScale.x, object.userData.originalScale.y, object.userData.originalScale.z)
              delete object.userData.originalScale;
            }
          }

          if(mod.material) {
            // Create new material object
            const newMaterial = new THREE.MeshStandardMaterial();
            if(mod.material.baseColor) {
              newMaterial.color.set(mod.material.baseColor);
            }
            if(mod.material.diffuse) {
              const t = texture(mod.material.diffuse.url).clone();
              t.wrapS = THREE.RepeatWrapping;
              t.wrapT = THREE.RepeatWrapping;
              const tiling = mod.material.tiling || [1, 1];
              t.repeat.set(...tiling);
              newMaterial.map = t;
            }
            if(mod.material.normalMap) {
              const t = texture(mod.material.normalMap.url).clone();
              t.wrapS = THREE.RepeatWrapping;
              t.wrapT = THREE.RepeatWrapping;
              const tiling = mod.material.tiling || [1, 1];
              t.repeat.set(...tiling);
              newMaterial.normalMap = t;
            }
            if(mod.material.roughness) {
              const t = texture(mod.material.roughness.url).clone();
              t.wrapS = THREE.RepeatWrapping;
              t.wrapT = THREE.RepeatWrapping;
              const tiling = mod.material.tiling || [1, 1];
              t.repeat.set(...tiling);
              newMaterial.roughnessMap = t;
            }

            const traverseCallback = (node : THREE.Object3D) => {
              if((node as THREE.Mesh).isMesh) {
                const nodeMesh : THREE.Mesh = node as THREE.Mesh;
                const materials = Array.isArray(nodeMesh.material) ? nodeMesh.material : [nodeMesh.material];
                if(materials.find(m => m?.name === mod.materialName)) {
                  if(!nodeMesh.userData.originalMaterial) {
                    nodeMesh.userData.originalMaterial = nodeMesh.material;
                  }

                  nodeMesh.material = newMaterial;
                }
              }
            }

            if(mod.materialName) {
              newMaterial.name = mod.materialName;

              object.traverse(traverseCallback);
            }
            else {
              if((object as THREE.Mesh).isMesh) {
                const mesh : THREE.Mesh = object as THREE.Mesh;

                if(!object.userData.originalMaterial) {
                  object.userData.originalMaterial = mesh.material;
                }

                mesh.material = newMaterial;
              }

            }
          }
          else if(object.userData.originalMaterial) {
            const traverseCallback = (node : THREE.Object3D) => {
              if((node as THREE.Mesh).isMesh) {
                const nodeMesh : THREE.Mesh = node as THREE.Mesh;
                const materials = Array.isArray(nodeMesh.material) ? nodeMesh.material : [nodeMesh.material];
                if(materials.find(m => m?.name === mod.materialName)) {
                  nodeMesh.material = node.userData.originalMaterial;
                  delete node.userData.originalMaterial;
                }
              }
            }

            if(mod.materialName) {
              object.traverse(traverseCallback);
            }
            else {
              if((object as THREE.Mesh).isMesh) {
                const mesh : THREE.Mesh = object as THREE.Mesh;

                mesh.material = mesh.userData.originalMaterial;
                delete mesh.userData.originalMaterial;
              }
            }
          }
        }
        else if(object && mod.type === 'light') {
          const light = object as THREE.Light;
          const pointLight = (light as THREE.PointLight).isPointLight ? light as THREE.PointLight : undefined;
          const spotLight = (light as THREE.SpotLight).isSpotLight ? light as THREE.SpotLight : undefined;

          if(mod.position) {
            if(!object.userData.originalPosition) {
              object.userData.originalPosition = object.position.clone();
            }
            object.position.set(...mod.position);
          }
          else {
            if(object.userData.originalPosition) {
              object.position.set(object.userData.originalPosition.x, object.userData.originalPosition.y, object.userData.originalPosition.z)
              delete object.userData.originalPosition;
            }
          }
          if(mod.intensity) {
            if(!light.userData.originalIntensity) {
              light.userData.originalIntensity = light.intensity;
            }
            light.intensity = mod.intensity;
          }
          else {
            if(light.userData.originalIntensity) {
              light.intensity = light.userData.originalIntensity;
              delete light.userData.originalIntensity;
            }
          }
          if(mod.color) {
            if(!light.userData.originalColor) {
              light.userData.originalColor = light.color.clone();
            }
            light.color.set(mod.color);
          }
          else {
            if(light.userData.originalColor) {
              light.color.set(light.userData.originalColor);
              delete light.userData.originalColor;
            }
          }

          if(pointLight) {
            if(mod.distance) {
              if(!pointLight.userData.originalDistance) {
                pointLight.userData.originalDistance = pointLight.distance;
              }
              pointLight.distance = mod.distance;
            }
            else {
              if(pointLight.userData.originalDistance) {
                pointLight.distance = pointLight.userData.originalDistance;
                delete pointLight.userData.originalDistance;
              }
            }
            if(mod.decay) {
              if(!pointLight.userData.originalDecay) {
                pointLight.userData.originalDecay = pointLight.decay;
              }
              pointLight.decay = mod.decay;
            }
            else {
              if(pointLight.userData.originalDecay) {
                pointLight.decay = pointLight.userData.originalDecay;
                delete pointLight.userData.originalDecay;
              }
            }
          }

          if(spotLight) {
            if(mod.distance) {
              if(!spotLight.userData.originalDistance) {
                spotLight.userData.originalDistance = spotLight.distance;
              }
              spotLight.distance = mod.distance;
            }
            else {
              if(spotLight.userData.originalDistance) {
                spotLight.distance = spotLight.userData.originalDistance;
                delete spotLight.userData.originalDistance;
              }
            }
            if(mod.decay) {
              if(!spotLight.userData.originalDecay) {
                spotLight.userData.originalDecay = spotLight.decay;
              }
              spotLight.decay = mod.decay;
            }
            else {
              if(spotLight.userData.originalDecay) {
                spotLight.decay = spotLight.userData.originalDecay;
                delete spotLight.userData.originalDecay;
              }
            }
            if(mod.angle) {
              if(!spotLight.userData.originalAngle) {
                spotLight.userData.originalAngle = spotLight.angle;
              }
              spotLight.angle = mod.angle;
            }
            else {
              if(spotLight.userData.originalAngle) {
                spotLight.angle = spotLight.userData.originalAngle;
                delete spotLight.userData.originalAngle;
              }
            }
            if(mod.penumbra) {
              if(!spotLight.userData.originalPenumbra) {
                spotLight.userData.originalPenumbra = spotLight.penumbra;
              }
              spotLight.penumbra = mod.penumbra;
            }
            else {
              if(spotLight.userData.originalPenumbra) {
                spotLight.penumbra = spotLight.userData.originalPenumbra;
                delete spotLight.userData.originalPenumbra;
              }
            }
          }
        }
      })
    })
  })

  stateManager.on('visibility', (event) => {
    const state = useViewerStore.getState();
    const ids = Object.keys(state.native);
    const {getObjectFromPath} = state.actions.model;
    const {setPreviousVisibilityModified} = state.actions.mods;

    const visibility = event.value;

    ids.forEach(objectId => {
      const previousVisibilityModified = state.previousVisibilityModified[objectId] || [];
      previousVisibilityModified.forEach(op => {
        const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
        if(object && typeof object.userData.originalVisibility === 'boolean') {
          object.visible = !!object.userData.originalVisibility;
        }
      })

      const hide = visibility.hide.filter(op => op.objectId === objectId);
      const show = visibility.show.filter(op => op.objectId === objectId);

      setPreviousVisibilityModified(objectId, [...hide, ...show].map(op => ({
        objectId: op.objectId,
        path: [...op.path]
      })));

      hide.forEach(op => {
        const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
        if(object) {
          if(typeof object.userData.originalVisibility === 'undefined') {
            object.userData.originalVisibility = object.visible;
          }
          object.visible = false;
        }
      })
      show.forEach(op => {
        const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
        if(object) {
          if(typeof object.userData.originalVisibility === 'undefined') {
            object.userData.originalVisibility = object.visible;
          }
          object.visible = true;
        }
      })
    })
  })
}

