import styled from "styled-components";

interface LoadingProps {
  progress : number;
  visible : boolean;
}

const Loading : React.FC<LoadingProps> = ({progress, visible}) => {
  if(!visible) return null;

  return (
    <Container>
      Loading... {Math.round(progress*100)}%
    </Container>
  )
}

export default Loading;

const Container = styled.div`
  position: absolute;
  bottom : 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
`