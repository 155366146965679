import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer'

import {ViewerStore, ObjectsTreeNode, ObjectsTreeValue} from './store-types';
import { Asset, Modification, Modification3D, ObjectPath } from '../model/ugla-filetype';
import * as THREE from 'three'
import { initStateStoreBindings } from './init-state-store-bindings';

export const useViewerStore = create<ViewerStore>()(
  immer<ViewerStore>(
    (set, get) => ({
      previousMods : {},
      previousVisibilityModified : {},
      textures : {},
      native : {},
      loadingStatus : {
        status : 'loaded',
        progress : 0,
        errors : {}
      },
      actions : {
        model : {
          setLoadingStatus : (status : 'loading' | 'loaded', progress ?: number) => {
            set(state => {
              state.loadingStatus.status = status;
              state.loadingStatus.progress = progress || 0;
            });
          },
          setLoadingError : (id : string, message : string) => {
            set(state => {
              state.loadingStatus.errors[id] = message;
            });
          },
          addNative : (id : string, native : THREE.Group<THREE.Object3DEventMap>) => {
            set(state => {
              native.userData.uglaId = id;
              state.native[id] = native;
              delete state.loadingStatus.errors[id];
            })
            get().actions.model.updateObjectsTree();
          },

          removeNative : (id : string) => {
            set(state => {
              delete state.native[id];
              delete state.loadingStatus.errors[id];
            })
            get().actions.model.updateObjectsTree();
          },

          updateObjectsTree : () => {
            const list = Object.entries(get().native);

            list.forEach(([objectId, group]) => group.userData.objectPath = {objectId, path : []});

            const iterate = (obj : THREE.Object3D<THREE.Object3DEventMap>, objectPath : ObjectPath) : ObjectsTreeNode => {
              obj.userData.objectPath = objectPath;
              return {
                label : obj.name,
                value : {obj, path : objectPath},
                children : (obj.children || []).map((child, index) => iterate(child, {objectId : objectPath.objectId, path : [...objectPath.path, child.name]}))
              }
            }

            const tree : ObjectsTreeNode = {
              label : 'UglaScene',
              value : {path : {objectId : '', path : []}},
              children : list.map(([objectId, group]) => ({
                label : `(${objectId}) ${group.name}`,
                value : {obj : group, path : {objectId, path : []}},
                children : (group.children || []).map((child, index) => iterate(child, {objectId, path : [child.name]}))
              }))
            }

            set(state => {state.objectsTree = tree});
          },

          getObjectFromPath : (path : ObjectPath) => {
            const root = get().objectsTree;
            if(!root) {return undefined;}
            const iterate = (node : ObjectsTreeNode) : ObjectsTreeValue | undefined => {
              if(
                node.value.path.objectId === path.objectId &&
                node.value.path.path.length === path.path.length &&
                node.value.path.path.every((i, pos) => i === path.path[pos])
              ) {
                return node.value;
              }

              const children = (node.children || []);
              for(let child of children) {
                const found : ObjectsTreeValue | undefined = iterate(child);
                if(found) {return found;}
              }

              return undefined;
            }
            return iterate(root);
          }

        },
        mods : {
          setPreviousMods : (id : string, mods : Modification<Asset>[]) => {
            set(state => {state.previousMods[id] = mods});
          },
          setPreviousVisibilityModified : (id : string, paths : ObjectPath[]) => {
            set(state => {state.previousVisibilityModified[id] = paths});
          }
        },
        textures : {
          texture : (url : string) => {
            if(get().textures[url]) {
              return get().textures[url];
            }
            else {
              const texture = new THREE.TextureLoader().load(url);
              set(state => {
                state.textures[url] = texture
              });

              return texture;
            }
          }
        }
      }
    })
  )
)


initStateStoreBindings();